/* eslint-disable */
<template>
  <b-overlay
    variant="white"
    :show="loading"
    spinner-variant="primary"
    blur="100"
    opacity="1"
    rounded="xs"
  >
    <div class="auth-wrapper auth-v2">
      <b-row class="auth-inner m-0">
        <!-- Brand logo-->
        <!-- <b-link class="brand-logo">
          <b-img
            fluid
            :src="logo"
            alt="Logo"
            width="200px;"
            class="desktop-logo"
          />
        </b-link> -->
        <!-- /Brand logo-->

        <!-- Left Text-->
        <b-col
          lg="8"
          class="d-none d-lg-flex align-items-center p-5"
        >
          <div
            class="w-100 d-lg-flex align-items-center justify-content-center px-5"
          >
          <a :href="bannerLink" target="_blank">
            <b-img
            id="banner"
            fluid
            style="max-height: 700px;"
            :src="banner"
            alt="Login V2"
            />
          </a>
            
          </div>
        </b-col>
        <!-- /Left Text-->

        <!-- Login-->
        <b-col
          lg="4"
          class="d-flex align-items-center auth-bg px-2 p-lg-5"
        >
          <b-col
            sm="8"
            md="6"
            lg="12"
            class="px-xl-2 mx-auto"
          >
            <div class="d-flex justify-content-center">
              <b-img
                fluid
                :src="logo"
                alt="Logo"
                width="50px;"
                class="mobile-logo"
              />
            </div>
            <b-card-title
              title-tag="h2"
              class="font-weight-bold mb-1"
            >
              Seja bem-vindo
            </b-card-title>
            <b-card-text class="mb-2">
              <b>{{ title }}</b> {{description}}
            </b-card-text>

            <!-- form -->
            <validation-observer ref="loginValidation">
              <b-form
                class="auth-login-form mt-2"
                @submit.prevent="validationForm()"
              >
                <!-- email -->
                <b-form-group
                  label="Email"
                  label-for="login-email"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="login-email"
                      v-model="userEmail"
                      :state="errors.length > 0 ? false : null"
                      name="login-email"
                      placeholder="usuario@example.com"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- forgot password -->
                <b-form-group>
                  <div class="d-flex justify-content-between">
                    <label for="login-password">Senha</label>
                    <b-link :to="{ name: 'forgetPassword' }">
                      <small>Esqueceu sua senha?</small>
                    </b-link>
                  </div>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Password"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-form-input
                        id="login-password"
                        v-model="password"
                        :state="errors.length > 0 ? false : null"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        name="login-password"
                        placeholder="············"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- checkbox -->
                <b-form-group>
                  <b-form-checkbox
                    id="remember-me"
                    v-model="status"
                    name="checkbox-1"
                  >
                    Lembrar de mim
                  </b-form-checkbox>
                </b-form-group>

                <!-- submit buttons -->
                <b-button
                  type="submit"
                  variant="primary"
                  block
                >
                  Login
                </b-button>
              </b-form>
            </validation-observer>

            <b-card-text class="text-center mt-2">
              <span>Não tem uma conta? </span>
              <b-link :to="{ name: 'register' }">
                <span>&nbsp;Criar conta</span>
              </b-link>
            </b-card-text>

            <!-- divider -->
            <div v-if="tokenFacebook !== null || tokenGoogle !== null" class="divider my-2">
              <div class="divider-text">
                Ou
              </div>
            </div>

            <!-- social buttons -->
            <div v-if="tokenFacebook !== null || tokenGoogle !== null" class="auth-footer-btn d-flex justify-content-center">
              <b-button
                v-if="tokenFacebook !== null"
                variant="facebook"
                @click="logInWithFacebook"
              >
                <feather-icon icon="FacebookIcon" />
              </b-button>
              <!--
            <b-button
              variant="twitter"
              href="javascript:void(0)"
            >
              <feather-icon icon="TwitterIcon" />
            </b-button>

            <b-button
              variant="google"
              href="javascript:void(0)"
            >
              <feather-icon icon="MailIcon" />
            </b-button>
            <b-button variant="github" href="javascript:void(0)">
              <feather-icon icon="GithubIcon" />
            </b-button> -->
              <GoogleLogin
                v-if="tokenGoogle !== null"
                :params="params"
                :on-success="onSuccess"
                :on-failure="onFailure"
                class="btn btn-google"
              >
                <feather-icon icon="MailIcon" />
              </GoogleLogin>
            </div>
          </b-col>
        </b-col>
      <!-- /Login-->
      </b-row>
    </div>
  </b-overlay>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import GoogleLogin from 'vue-google-login'

import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store/index'
import useJwt from '@/auth/jwt'
import axios from 'axios'

import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import { mapActions } from 'vuex'

export default {
  components: {
    GoogleLogin,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      loading: false,
      status: '',
      password: '',
      userEmail: '',
      sideImg: '',
      tokenGoogle: null, // Variável para armazenar o token do formulário 1
      tokenFacebook: null,
      // validation rulesimport store from '@/store/index'
      required,
      email,
      params: {
        client_id: process.env.VUE_APP_GOOGLE_ID,
      },
      logo: '',
      banner: '',
      bannerLink: '',
      title: '',
      description: '',
      renderParams: {
        width: 250,
        height: 50,
        longtitle: true,
      },
      loginMethods: {
        facebook_id: process.env.VUE_APP_FACEBOOK_ID,
        google_id: process.env.VUE_APP_GOOGLE_ID,
      },
      
      payload: {}
    }
  },
  computed: {
    hasAlternativeLoginMethods () {
      return Object.values(this.loginMethods).some(method => method !== 'null')
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = this.banner
        return this.sideImg
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.sideImg = this.banner
      return this.sideImg
    },    
    hasAlternativeLoginMethods () {
      return Object.values(this.loginMethods).some(method => method !== 'null')
    }
  },
  watch: {
    userEmail(string) {
      console.log(string)
      this.userEmail = string.replace(/ /g, '')
    }
  },
  created() {
    this.loadFacebookSDK()
  },
  beforeMount() {
    this.getPersonalizacao()
    this.getTokenSocial()
  },
  methods: {
    ...mapActions('auth', [
      'ActionLoadSessionInfo',
      'ActionSetUser'
    ]),
    validationForm() {
      this.$refs.loginValidation.validate().then(success => {
        if (success) {
          this.login()
        }
      })
    },
    getTokenSocial(){
      axios.get(`${process.env.VUE_APP_API}/social_token`)
        .then(response => {

          if(response.data.response.tokenFacebook != null){
            this.tokenFacebook = response.data.response.tokenFacebook.value;
          }
          if(response.data.response.tokenGoogle != null){
            this.tokenGoogle = response.data.response.tokenGoogle.value;
          }
          console.log(this.tokenFacebook)
          console.log(this.tokenGoogle)
        })
        .catch(error => {
          console.log(error);
        });
    },
    getPersonalizacao() {
      const logoLocalStorage = localStorage.getItem('logo');
      const bannerLocalStorage = localStorage.getItem('banner');
      const nameLocalStorage = localStorage.getItem('name');
      const descriptionLocalStorage = localStorage.getItem('description');
      const bannerLinkLocalStorage = localStorage.getItem('bannerLink');

      let requestGetOn = true

      if (typeof logoLocalStorage === 'undefined' || logoLocalStorage === null || logoLocalStorage.includes('undefined') || logoLocalStorage.includes('null')) {
        this.logo = require('@/assets/default/logo.png')
        requestGetOn = true
      } else {
        this.logo = logoLocalStorage
      }

      if (typeof bannerLocalStorage === 'undefined' || bannerLocalStorage === null || bannerLocalStorage.includes('undefined') || bannerLocalStorage.includes('null')) {
        this.banner = require('@/assets/default/banner.png')
        requestGetOn = true
      } else {
        this.banner = bannerLocalStorage
      }

      if (typeof nameLocalStorage === 'undefined' || nameLocalStorage === null || nameLocalStorage.includes('undefined') || nameLocalStorage.includes('null')) {
        this.title = 'Plataforma OP3'
        requestGetOn = true
      } else {
        this.title = nameLocalStorage
      }

      if (typeof descriptionLocalStorage === 'undefined' || descriptionLocalStorage === null || descriptionLocalStorage.includes('undefined') || descriptionLocalStorage.includes('null')) {
        this.description = 'uma plataforma completa para transmissões ao vivo, gravação, gestão e controle de cursos'
        requestGetOn = true
      } else {
        this.description = descriptionLocalStorage
      }

      if (typeof bannerLinkLocalStorage === 'undefined' || bannerLinkLocalStorage === null || bannerLinkLocalStorage.includes('undefined') || bannerLinkLocalStorage.includes('null')) {
        this.bannerLink = null
        requestGetOn = true
      } else {
        this.bannerLink = bannerLinkLocalStorage
      }

      if(requestGetOn == true){
        axios.get(`${process.env.VUE_APP_API}/personalizar`)
          .then(response => {
            if(response.data.response.logo != null && response.data.response.logo != 'undefined' && !response.data.response.logo.includes('undefined') && !response.data.response.logo.includes('null')){
              this.logo = `${process.env.VUE_APP_API_BASE}/storage/${response.data.response.logo}`
              localStorage.setItem('logo', this.logo);

            }
            if(response.data.response.banner != null && response.data.response.banner != 'undefined' && !response.data.response.banner.includes('undefined') && !response.data.response.banner.includes('null')){
              this.banner = `${process.env.VUE_APP_API_BASE}/storage/${response.data.response.banner}`
              localStorage.setItem('banner', this.banner);

            }
            if(response.data.response.name != null && response.data.response.name != 'undefined' && !response.data.response.name.includes('undefined') && !response.data.response.name.includes('null')){
              this.title = `${response.data.response.name}`
              localStorage.setItem('title', this.title);

            }
            if(response.data.response.description != null && response.data.response.description != 'undefined' && !response.data.response.description.includes('undefined') && !response.data.response.description.includes('null')){
              this.description = `${response.data.response.description}`
              localStorage.setItem('description', this.description);
            }

            if(response.data.response.bannerLink != null && response.data.response.bannerLink != 'undefined' && !response.data.response.bannerLink.includes('undefined') && !response.data.response.bannerLink.includes('null')){
              this.bannerLink = `${response.data.response.bannerLink}`
              localStorage.setItem('bannerLink', this.bannerLink);
            }
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
    async onSuccess(googleUser) {
      this.loading = true
      // get Basic user information
      const gmailData = Object.values(googleUser.getBasicProfile())
      console.log(gmailData)
      this.payload = {
        id: gmailData[0],
        name: gmailData[1],
        email: gmailData[5],
        photo: gmailData[4]
      }

      console.log(this.payload)
      const login = await useJwt.googleLogin(
        this.payload
      )

      if (login.data.status === 200) {
        const userData = await login.data.response.user
        localStorage.setItem('userData', JSON.stringify(userData))
        useJwt.setToken(login.data.response.token)
        await this.ActionLoadSessionInfo(login.data.response)
        await this.ActionSetUser(login.data.response.user)
        this.$router.replace(getHomeRouteForLoggedInUser(userData.role))

        this.$toast({
          component: ToastificationContent,
          props: {
            title: login.data.message,
            icon: 'EditIcon',
            variant: 'success',
          },
        })
      } else {
        this.loading = false
        console.log(login.data.errors)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: login.data.message,
            icon: 'EditIcon',
            variant: 'danger',
          },
        })
      }
    },
    onFailure(error) {
      console.log(error)
    },
    async logInWithFacebook() {
      this.loading = true
      await this.initFacebook()
      await window.FB.login(response => {
        if (response.authResponse) {
          // Now you can redirect the user or do an AJAX request to
          // a PHP script that grabs the signed request from the cookie.
          console.log(response)
          window.FB.api('/me', {
            access_token: response.authResponse.access_token,
            fields: 'name,email,picture',
          }, (res) => {
            this.payload = {
              id: res.id,
              name: res.name,
              email: res.email,
              photo: res.picture.data.url
            }
            useJwt.facebookLogin(
              this.payload
            ).then((login) => {
              // console.log(login)
              // console.log(this.payload)
              if (login.data.status === 200) {
                const userData = login.data.response.user
                localStorage.setItem('userData', JSON.stringify(userData))
                useJwt.setToken(login.data.response.token)
                this.ActionLoadSessionInfo(login.data.response)
                this.ActionSetUser(login.data.response.user)
                this.$router.replace(getHomeRouteForLoggedInUser(userData.role))

                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: login.data.message,
                    icon: 'EditIcon',
                    variant: 'success',
                  },
                })
              } else {
                this.loading = false
                console.log(login.data.errors)
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: login.data.message,
                    icon: 'EditIcon',
                    variant: 'danger',
                  },
                })
              }
            })
          })
        } else {
          this.loading = false
          console.log('User cancelled login or did not fully authorize.')
        }
      }, { scope: 'email' })
    },
    initFacebook() {
      return window.FB.init({
        appId: process.env.VUE_APP_FACEBOOK_ID, // You will need to change this
        cookie: true, // This is important, it's not enabled by default
        version: process.env.VUE_APP_FACEBOOK_VERSION,
      })
    },
    loadFacebookSDK() {
      (function (d, s, id) {
        let js = d.getElementsByTagName(s)[0]
        const fjs = js
        if (d.getElementById(id)) { return }
        js = d.createElement(s); js.id = id
        js.src = 'https://connect.facebook.net/en_US/sdk.js'
        fjs.parentNode.insertBefore(js, fjs)
      }(document, 'script', 'facebook-jssdk'))
    },
    async login() {
      this.loading = true
      const login = await useJwt.login({
        email: this.userEmail,
        password: this.password,
      })
      // console.log(login)
      if (login.data.status === 200) {
        const userData = await login.data.response.user
        await this.ActionLoadSessionInfo(login.data.response)
        await this.ActionSetUser(login.data.response.user)
        localStorage.setItem('userData', JSON.stringify(userData))
        useJwt.setToken(login.data.response.token)
        this.$router.replace(getHomeRouteForLoggedInUser(userData.role))

        this.$toast({
          component: ToastificationContent,
          props: {
            title: login.data.message,
            icon: 'EditIcon',
            variant: 'success',
          },
        })
      } else if (login.data.status === 100) {
        this.loading = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Email ou senha inválidos',
            icon: 'EditIcon',
            variant: 'danger',
          },
        })
      } else {
        this.loading = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Email ou senha inválidos',
            icon: 'EditIcon',
            variant: 'danger',
          },
        })
      }
    }
  },
}

</script>

  <style lang="scss">
    @import '@core/scss/vue/pages/page-auth.scss';
    @media (min-width: 0px) and (max-width: 991px) {
    .mobile-logo {
    display: block;
    }
    .desktop-logo {
    display: none;
    }
    }
    @media (min-width: 992px){
    .desktop-logo {
    display: block;
    }
    .mobile-logo {
    display: none;
    }
    }
    .mb-2, .mb-1 {
    text-align: center;
    }
    #banner {
      border-radius: 0 50px;
    }
  </style>
